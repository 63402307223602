import './Main.css';
import Profile from './Profile';

export default function Main() {
	return (
		<main>
			<Profile />
			{/* TODO: "WORKING ON" section. */}
		</main>
	);
}

